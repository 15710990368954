@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Cuprum:400,400i,700,700i&subset=cyrillic");
body {
  font-family: "Cuprum", sans-serif;
  font-weight: 400;
  font-size: 16px; }

header {
  position: fixed;
  width: 100%;
  z-index: 100; }
  header .row {
    position: relative;
    align-items: center; }
  header ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none; }
    header ul li {
      margin-right: 45px; }
      header ul li:last-of-type {
        margin-right: 0; }
      header ul li a {
        font-size: 18px;
        color: #ffffff;
        text-decoration: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.35);
        transition: all 0.25s ease; }
        header ul li a:hover, header ul li a.mPS2id-highlight {
          color: #f7b814;
          border-color: rgba(247, 184, 20, 0.75);
          text-decoration: none; }
  header .phone_col {
    padding-left: 40px; }
    header .phone_col span {
      padding-left: 32px;
      font-size: 16px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.8); }
      header .phone_col span span {
        padding-left: 0; }
    header .phone_col p {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 32px;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      color: #f8be26 !important;
      background: url("../img/icons/orange_phone.png") no-repeat left center; }

.scroll_header {
  background-color: rgba(32, 32, 32, 0.85); }

.main_screen {
  background: url("../img/main_screen_bg.jpg") no-repeat center;
  background-size: cover; }
  .main_screen > .container {
    position: relative;
    padding-top: 129px;
    padding-bottom: 55px; }
    .main_screen > .container:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: rgba(255, 255, 255, 0.5);
      top: 0;
      left: 3px; }
  .main_screen .col_left {
    padding-top: 41px; }
  .main_screen h1 {
    margin-bottom: 32px;
    font-size: 54px;
    font-weight: 700;
    line-height: 62px;
    color: #ffffff; }
  .main_screen h4 {
    max-width: 340px;
    margin-bottom: 68px;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: #ffffff; }
  .main_screen .main_screen_desc {
    padding-top: 40px;
    padding-left: 67px;
    padding-right: 67px; }
    .main_screen .main_screen_desc .item {
      margin-bottom: 52px;
      padding-left: 83px;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff; }
      .main_screen .main_screen_desc .item:nth-of-type(1) {
        background: url("../img/icons/main_screen1.png") no-repeat left 15px center; }
      .main_screen .main_screen_desc .item:nth-of-type(2) {
        background: url("../img/icons/main_screen2.png") no-repeat left 15px center; }
      .main_screen .main_screen_desc .item:nth-of-type(3) {
        background: url("../img/icons/main_screen3.png") no-repeat left 15px center; }
      .main_screen .main_screen_desc .item:nth-of-type(4) {
        background: url("../img/icons/main_screen4.png") no-repeat left 15px center; }
  .main_screen .col_right {
    padding-right: 78px; }
  .main_screen form {
    padding-top: 36px;
    padding-bottom: 25px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 12px 1px rgba(0, 0, 0, 0.12); }
    .main_screen form h3 {
      margin-bottom: 32px;
      padding-left: 48px;
      padding-right: 42px;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: #202020;
      text-align: center; }
    .main_screen form p {
      position: relative;
      padding-left: 66px;
      padding-right: 58px;
      font-size: 16px;
      line-height: 20px;
      color: rgba(32, 32, 32, 0.85); }
      .main_screen form p:before {
        position: absolute;
        top: -2px;
        left: 44px;
        font-size: 24px;
        font-weight: 700;
        color: #f7b814; }
      .main_screen form p:nth-of-type(1):before {
        content: '1.'; }
      .main_screen form p:nth-of-type(2):before {
        content: '2.'; }
      .main_screen form p:nth-of-type(3):before {
        content: '3.'; }
    .main_screen form input[type="text"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 14px;
      padding-bottom: 16px;
      padding-left: 52px;
      font-size: 18px;
      color: rgba(32, 32, 32, 0.8);
      outline: none;
      border: 2px solid #cccccc;
      border-radius: 60px;
      background: url("../img/icons/input_phone.png") no-repeat left 27px center;
      transition: all 0.25s ease; }
      .main_screen form input[type="text"]:hover, .main_screen form input[type="text"]:focus {
        border-color: #f7b814; }
    .main_screen form button[type="submit"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 34px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 4px;
      font-size: 18px;
      letter-spacing: 1.3px;
      color: #202020;
      text-transform: uppercase;
      line-height: 56px;
      outline: none;
      border: none;
      border-radius: 60px;
      background: linear-gradient(135deg, #ffcc3e 0%, #f7b714 100%);
      box-shadow: 0 3px 0 0 #d19e19;
      cursor: pointer;
      transition: all 0.25s ease; }
      .main_screen form button[type="submit"]:hover {
        background: linear-gradient(135deg, #f7b714 0%, #ffcc3e 100%); }
  .main_screen .chekbox_wrap {
    margin-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .main_screen .chekbox_wrap .ui-state-default, .main_screen .chekbox_wrap .ui-widget-content .ui-state-default, .main_screen .chekbox_wrap .ui-widget-header .ui-state-default, .main_screen .chekbox_wrap .ui-button, .main_screen .chekbox_wrap html .ui-button.ui-state-disabled:hover, .main_screen .chekbox_wrap html .ui-button.ui-state-disabled:active {
      border: 0;
      background: none;
      margin-bottom: 0;
      margin-right: 10px; }
    .main_screen .chekbox_wrap .ui-button .ui-icon {
      background: url("../img/icons/checked_white.png") no-repeat center;
      transition: all 0.25s ease; }
    .main_screen .chekbox_wrap .ui-icon {
      height: 18px;
      width: 18px;
      margin-top: 0; }
    .main_screen .chekbox_wrap .ui-checkboxradio-label .ui-icon-background {
      box-shadow: none;
      border: 1px solid #FFDD69;
      border-radius: 4px; }
    .main_screen .chekbox_wrap .ui-state-active .ui-icon, .main_screen .chekbox_wrap .ui-button:active .ui-icon {
      background-color: #FFDD69; }
    .main_screen .chekbox_wrap .ui-button {
      padding: 0;
      outline: 0;
      box-shadow: none; }
    .main_screen .chekbox_wrap .checkbox_span {
      font-size: 15px;
      line-height: 18px;
      color: #606060; }
      .main_screen .chekbox_wrap .checkbox_span a {
        font-size: 15px;
        line-height: 18px;
        color: #606060;
        text-decoration: none;
        border-bottom: 1px solid rgba(96, 96, 96, 0.4);
        transition: all 0.25s ease; }
        .main_screen .chekbox_wrap .checkbox_span a:hover {
          border-bottom: 1px solid transparent;
          color: #f7b814;
          text-decoration: none; }

.vertical_left {
  display: inline-block;
  position: absolute;
  top: 294px;
  left: -19px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  transform: rotate(270deg); }
  .vertical_left:before {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 50px;
    top: 10px;
    left: -80px;
    background-color: rgba(255, 255, 255, 0.5); }
  .vertical_left:after {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 50px;
    right: -80px;
    bottom: 10px;
    background-color: rgba(255, 255, 255, 0.5); }

.vertical_right {
  display: inline-block;
  position: absolute;
  top: 205px;
  right: -38px;
  font-size: 14px;
  color: rgba(32, 32, 32, 0.5);
  text-transform: uppercase;
  transform: rotate(270deg); }
  .vertical_right:before {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 50px;
    top: 10px;
    left: -80px;
    background-color: #eaeaea; }
  .vertical_right:after {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 50px;
    right: -80px;
    bottom: 10px;
    background-color: #eaeaea; }

.advantages {
  padding-top: 110px;
  padding-bottom: 100px;
  background: url("../img/advantages_left_bg.jpg") no-repeat left center, url("../img/advantages_right_bg.jpg") no-repeat right bottom 70px; }
  .advantages > .container {
    position: relative; }
    .advantages > .container:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: -108px;
      right: 5px; }
  .advantages h2 {
    margin-bottom: 84px;
    padding-left: 67px;
    font-size: 54px;
    line-height: 62px;
    color: #606060; }
    .advantages h2 b {
      font-weight: 700;
      color: #f7b814; }
  .advantages .col_left {
    margin-bottom: 50px;
    padding-left: 82px; }
    .advantages .col_left:nth-of-type(2) {
      padding-top: 82px; }
  .advantages .col_right {
    margin-bottom: 50px;
    padding-right: 82px; }
    .advantages .col_right:nth-of-type(4) {
      padding-top: 82px; }
    .advantages .col_right .advantages_item_wrap {
      margin-left: auto; }
  .advantages .advantages_item_wrap {
    position: relative;
    max-width: 323px;
    padding-top: 25px;
    box-shadow: 0 10px 35px 1px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    background-color: #ffffff; }
    .advantages .advantages_item_wrap .num {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 24px;
      font-weight: 700;
      line-height: 40px;
      width: 40px;
      text-align: center;
      color: #ffffff;
      background-color: #f7b814;
      border-radius: 50%; }
    .advantages .advantages_item_wrap h4 {
      margin-bottom: 2px;
      padding-right: 20px;
      padding-left: 80px;
      font-size: 24px;
      line-height: 30px;
      color: #202020; }
    .advantages .advantages_item_wrap p {
      margin-bottom: 0;
      padding-left: 80px;
      padding-right: 20px;
      font-size: 18px;
      line-height: 30px;
      color: #202020; }
    .advantages .advantages_item_wrap img {
      margin-top: 42px;
      border-radius: 0 0 10px 10px; }

.free_calculation {
  background: url("../img/free_calculation_bg1.jpg") no-repeat center;
  background-size: cover; }
  .free_calculation .vertical_left {
    top: 321px;
    left: -57px; }
  .free_calculation > .container {
    position: relative;
    padding-top: 93px;
    padding-bottom: 91px; }
    .free_calculation > .container:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: rgba(255, 255, 255, 0.5);
      top: 0;
      left: -7px; }
  .free_calculation .calculation_form_wrap {
    max-width: 460px;
    margin-left: 164px; }
  .free_calculation h2 {
    margin-bottom: 23px;
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;
    color: #ffffff; }
  .free_calculation p {
    font-size: 24px;
    line-height: 30px;
    color: #ffffff; }
    .free_calculation p:last-of-type {
      margin-bottom: 26px; }
  .free_calculation form input[type="text"] {
    display: block;
    max-width: 290px;
    width: 100%;
    margin-bottom: 30px;
    padding-top: 14px;
    padding-bottom: 16px;
    padding-left: 52px;
    font-size: 18px;
    color: rgba(32, 32, 32, 0.8);
    outline: none;
    border: 2px solid transparent;
    border-radius: 60px;
    background: url("../img/icons/input_phone.png") no-repeat left 27px center;
    transition: all 0.25s ease;
    background-color: #ffffff; }
    .free_calculation form input[type="text"]:hover, .free_calculation form input[type="text"]:focus {
      border-color: #f7b814; }
  .free_calculation form button[type="submit"] {
    display: block;
    max-width: 290px;
    width: 100%;
    margin-bottom: 34px;
    padding-top: 4px;
    font-size: 18px;
    letter-spacing: 1.3px;
    color: #202020;
    text-transform: uppercase;
    line-height: 56px;
    outline: none;
    border: none;
    border-radius: 60px;
    background: linear-gradient(135deg, #ffcc3e 0%, #f7b714 100%);
    box-shadow: 0 3px 0 0 #d19e19;
    cursor: pointer;
    transition: all 0.25s ease; }
    .free_calculation form button[type="submit"]:hover {
      background: linear-gradient(135deg, #f7b714 0%, #ffcc3e 100%); }
  .free_calculation form .chekbox_wrap {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .free_calculation form .chekbox_wrap .ui-state-default, .free_calculation form .chekbox_wrap .ui-widget-content .ui-state-default, .free_calculation form .chekbox_wrap .ui-widget-header .ui-state-default, .free_calculation form .chekbox_wrap .ui-button, .free_calculation form .chekbox_wrap html .ui-button.ui-state-disabled:hover, .free_calculation form .chekbox_wrap html .ui-button.ui-state-disabled:active {
      border: 0;
      background: none;
      margin-bottom: 0;
      margin-right: 10px; }
    .free_calculation form .chekbox_wrap .ui-button .ui-icon {
      background: url("../img/icons/checked_white.png") no-repeat center;
      transition: all 0.25s ease; }
    .free_calculation form .chekbox_wrap .ui-icon {
      height: 18px;
      width: 18px;
      margin-top: 0; }
    .free_calculation form .chekbox_wrap .ui-checkboxradio-label .ui-icon-background {
      box-shadow: none;
      border: 1px solid #FFDD69;
      border-radius: 4px;
      background-color: #ffffff; }
    .free_calculation form .chekbox_wrap .ui-state-active .ui-icon, .free_calculation form .chekbox_wrap .ui-button:active .ui-icon {
      background-color: #FFDD69; }
    .free_calculation form .chekbox_wrap .ui-button {
      padding: 0;
      outline: 0;
      box-shadow: none; }
    .free_calculation form .chekbox_wrap .checkbox_span {
      font-size: 18px;
      line-height: 20px;
      color: #ffffff; }
      .free_calculation form .chekbox_wrap .checkbox_span a {
        font-size: 18px;
        line-height: 20px;
        color: #ffffff;
        text-decoration: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        transition: all 0.25s ease; }
        .free_calculation form .chekbox_wrap .checkbox_span a:hover {
          border-bottom: 1px solid transparent;
          color: #f7b814;
          text-decoration: none; }

.free_calculation_2 {
  background: url("../img/free_calculation_bg2.jpg") no-repeat center;
  background-size: cover; }

.free_calculation_3 {
  background: url("../img/free_calculation_bg3.jpg") no-repeat center;
  background-size: cover; }

.free_calculation_4 {
  background: url("../img/free_calculation_bg4.jpg") no-repeat center;
  background-size: cover; }

.free_calculation_5 {
  background: url("../img/free_calculation_bg5.jpg") no-repeat center;
  background-size: cover; }

.free_calculation_6 {
  background: url("../img/free_calculation_bg6.jpg") no-repeat center;
  background-size: cover; }

.free_calculation_7 {
  background: url("../img/free_calculation_bg7.jpg") no-repeat center;
  background-size: cover; }

.free_calculation_8 {
  background: url("../img/free_calculation_bg8.jpg") no-repeat center;
  background-size: cover; }

.free_calculation_9 {
  background: url("../img/free_calculation_bg9.jpg") no-repeat center;
  background-size: cover; }

.free_calculation_10 {
  background: url("../img/free_calculation_bg10.jpg") no-repeat center;
  background-size: cover; }

.work_examples > .container {
  position: relative;
  padding-top: 110px;
  padding-bottom: 120px; }
  .work_examples > .container:after {
    content: "";
    position: absolute;
    display: block;
    height: 150px;
    width: 3px;
    background-color: #eaeaea;
    top: 0;
    right: 5px; }

.work_examples .vertical_right {
  top: 315px;
  right: -40px; }

.work_examples h2 {
  margin-bottom: 16px;
  padding-left: 67px;
  padding-right: 67px;
  font-size: 54px;
  line-height: 60px;
  color: #606060; }
  .work_examples h2 b {
    font-weight: 700;
    color: #f7b814; }

.work_examples p {
  padding-left: 67px;
  padding-right: 67px;
  font-size: 24px;
  color: #202020; }
  .work_examples p:last-of-type {
    margin-bottom: 89px; }

.examples_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 67px;
  padding-right: 67px; }

.item_290 {
  max-width: 290px; }

.item_365 {
  max-width: 365px; }

.example_item {
  margin-bottom: 30px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1); }
  .example_item .example_logo {
    position: absolute;
    top: 10px;
    right: 10px; }
  .example_item .example_price {
    position: absolute;
    left: 24px;
    bottom: 11px; }
    .example_item .example_price p {
      margin-bottom: 12px;
      padding-right: 0;
      padding-left: 0;
      font-size: 18px;
      line-height: 16px;
      color: #ffffff; }

.individual_project {
  padding-top: 10px;
  padding-bottom: 104px; }

.individual_project_wrap {
  margin-left: 67px;
  margin-right: 67px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.1); }

.in_ind_project {
  position: relative;
  padding-top: 34px;
  padding-bottom: 24px;
  border: 2px dashed rgba(255, 221, 105, 0.5);
  border-radius: 10px; }
  .in_ind_project img {
    position: absolute;
    left: 45px;
    bottom: -16px; }
  .in_ind_project h3 {
    margin-bottom: 15px;
    padding-left: 67px;
    font-size: 36px;
    color: #202020; }
  .in_ind_project p {
    padding-left: 67px;
    padding-right: 240px;
    font-size: 18px;
    line-height: 24px;
    color: #202020;
    background: url("../img/icons/individual_project_ico.png") no-repeat right 48px center; }

.headsets_types {
  background: url("../img/headsets_bg_left.jpg") no-repeat left bottom 30px; }
  .headsets_types .vertical_right {
    top: 324px;
    right: -47px; }
  .headsets_types > .container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 85px; }
    .headsets_types > .container:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      right: 5px; }
  .headsets_types h2 {
    margin-bottom: 86px;
    padding-left: 67px;
    padding-right: 67px;
    font-size: 54px;
    line-height: 62px;
    color: #606060; }
    .headsets_types h2 b {
      font-weight: 700;
      color: #f7b814; }
  .headsets_types .headsets_types_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 67px;
    margin-right: 67px; }
  .headsets_types .headsets_item {
    position: relative;
    max-width: 292px;
    margin-bottom: 65px;
    border-radius: 10px;
    box-shadow: 0 10px 35px 1px rgba(0, 0, 0, 0.1); }
    .headsets_types .headsets_item p {
      position: absolute;
      width: 100%;
      top: 32px;
      text-align: center; }
      .headsets_types .headsets_item p span {
        position: relative;
        font-size: 24px;
        color: #202020; }
        .headsets_types .headsets_item p span:before {
          content: "";
          position: absolute;
          display: block;
          height: 8px;
          width: 8px;
          top: 9px;
          left: -18px;
          background-color: #f7b814;
          border-radius: 50%; }

.when_order {
  background: url("../img/when_order_bg_left.jpg") no-repeat left center, url("../img/when_order_bg_right.jpg") no-repeat right center;
  background-size: auto 100%; }
  .when_order > .container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 150px; }
    .when_order > .container:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      right: 5px; }
  .when_order .vertical_right {
    top: 292px;
    right: -16px; }
  .when_order h2 {
    margin-bottom: 84px;
    padding-left: 67px;
    font-size: 54px;
    line-height: 62px;
    color: #606060; }
    .when_order h2 b {
      font-weight: 700;
      color: #f7b814; }

.when_order_left {
  margin-left: 67px;
  position: relative; }
  .when_order_left .logo {
    position: absolute;
    right: 10px;
    bottom: 12px; }

.when_order_desc {
  position: relative;
  margin-top: 50px;
  margin-left: 18px;
  margin-right: 97px;
  padding-top: 28px;
  padding-bottom: 34px;
  padding-right: 42px;
  padding-left: 42px;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
  border: 8px solid #ffdd69; }
  .when_order_desc:before {
    content: '';
    position: absolute;
    top: 34px;
    left: -28px;
    border: 8px solid transparent;
    border-right: 12px solid #ffdd69; }

.when_order_ico {
  margin-top: 33px;
  text-align: right; }
  .when_order_ico img {
    margin-right: 97px; }

.compare {
  background: url("../img/compare_bg_left.jpg") no-repeat left 39px top 109px, url("../img/compare_bg_right.jpg") no-repeat right 39px bottom 50px; }
  .compare > .container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 73px; }
    .compare > .container:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      right: 5px; }
  .compare .vertical_right {
    top: 306px;
    right: -31px; }
  .compare h2 {
    padding-left: 67px;
    padding-right: 67px;
    margin-bottom: 84px;
    font-size: 54px;
    line-height: 62px;
    color: #606060; }
    .compare h2 b {
      font-weight: 700;
      color: #f7b814; }
  .compare ul {
    margin-left: 66px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none; }
    .compare ul li {
      padding-top: 18px;
      padding-right: 20px;
      padding-bottom: 22px;
      padding-left: 36px;
      position: relative;
      max-width: 293px;
      margin-bottom: 25px;
      border: 1px solid #ffffff;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 5px 25px 1px rgba(0, 0, 0, 0.08); }
      .compare ul li:before {
        content: "«";
        position: absolute;
        left: -50px;
        top: 12px;
        font-size: 50px;
        color: #606060;
        transform: rotate(180deg); }
      .compare ul li p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 27px;
        color: #606060; }
        .compare ul li p.price {
          font-weight: 700;
          color: #f7b814; }
      .compare ul li:last-of-type {
        border: 1px solid #ffdd69;
        background: #ffffff url("../img/icons/compare_coin.png") no-repeat right 30px center; }
        .compare ul li:last-of-type p {
          font-size: 22px;
          line-height: 36px;
          color: #202020; }
          .compare ul li:last-of-type p.price {
            font-size: 32px;
            color: #f7b814; }
        .compare ul li:last-of-type:before {
          top: 17px;
          color: #f7b814; }

.compare_left {
  max-width: 585px;
  margin-left: 67px; }
  .compare_left .img_wrap {
    margin-bottom: 26px;
    position: relative; }
    .compare_left .img_wrap .logo {
      position: absolute;
      right: 11px;
      bottom: 12px; }
  .compare_left p {
    padding-left: 30px;
    max-width: 300px;
    font-size: 18px;
    line-height: 24px;
    color: #606060; }
    .compare_left p b {
      font-weight: 700;
      color: #202020; }

.calculator {
  background: url("../img/calculator_bg.jpg") center;
  background-size: cover; }
  .calculator > .container {
    position: relative;
    padding-top: 120px;
    padding-bottom: 107px; }
    .calculator > .container:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      left: -7px; }
  .calculator .vertical_left {
    top: 322px;
    left: -57px;
    color: rgba(32, 32, 32, 0.5); }
    .calculator .vertical_left:after {
      background-color: #eaeaea; }
    .calculator .vertical_left:before {
      background-color: #eaeaea; }
  .calculator h2 {
    padding-left: 67px;
    padding-right: 67px;
    margin-bottom: 82px;
    font-size: 54px;
    font-weight: 700;
    color: #f7b814; }
  .calculator .img_wrap {
    position: relative;
    max-width: 322px;
    margin-left: 67px;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1); }
    .calculator .img_wrap img {
      border-radius: 10px; }
    .calculator .img_wrap p {
      position: absolute;
      top: 30px;
      left: 37px;
      max-width: 250px;
      font-size: 24px;
      color: #202020;
      line-height: 30px; }
  .calculator form {
    padding-top: 35px;
    padding-bottom: 25px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1); }
    .calculator form h4 {
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 28px;
      color: #202020;
      text-align: center; }
    .calculator form .col_left {
      padding-left: 45px; }
      .calculator form .col_left .ui-selectmenu-button.ui-button {
        margin-bottom: 30px;
        max-width: 290px;
        width: 100%;
        padding: 0 0 0 26px;
        font-size: 18px;
        color: rgba(32, 32, 32, 0.8);
        line-height: 60px;
        border: 2px solid #cccccc;
        border-radius: 60px;
        outline: none !important;
        background-color: #ffffff; }
        .calculator form .col_left .ui-selectmenu-button.ui-button:hover, .calculator form .col_left .ui-selectmenu-button.ui-button:active {
          border-color: #f7b814; }
      .calculator form .col_left .ui-button .ui-icon, .calculator form .col_left .ui-state-active .ui-icon, .calculator form .col_left .ui-button:active .ui-icon {
        position: relative;
        background: url("../img/icons/select_arrow.png") no-repeat center;
        top: 21px;
        right: 27px; }
      .calculator form .col_left .calculator form .ui-button .ui-icon, .calculator form .col_left .ui-selectmenu-button-open .ui-icon, .calculator form .col_left .calculator form .ui-state-active .ui-icon, .calculator form .col_left .calculator form .ui-button:active .ui-icon {
        transform: rotate(180deg) !important; }
      .calculator form .col_left .ui-state-active .ui-icon, .calculator form .col_left .ui-button:active .ui-icon {
        transform: rotate(180deg); }
      .calculator form .col_left .ui-widget.ui-widget-content {
        border: 2px solid #f7b814;
        border-radius: 60px; }
      .calculator form .col_left input[type="text"] {
        display: block;
        max-width: 290px;
        width: 100%;
        margin-bottom: 30px;
        padding-left: 26px;
        padding-top: 14px;
        padding-bottom: 16px;
        font-size: 18px;
        color: rgba(32, 32, 32, 0.8);
        outline: none !important;
        border: 2px solid #cccccc;
        border-radius: 60px;
        background-color: #ffffff;
        transition: all 0.25s ease; }
        .calculator form .col_left input[type="text"]:hover, .calculator form .col_left input[type="text"]:focus {
          border-color: #f7b814; }
    .calculator form .col_right input[type="text"]:nth-of-type(2) {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 30px;
      padding-top: 14px;
      padding-bottom: 16px;
      padding-left: 52px;
      font-size: 18px;
      color: rgba(32, 32, 32, 0.8);
      outline: none !important;
      border: 2px solid #cccccc;
      border-radius: 60px;
      background: #ffffff url("../img/icons/input_phone.png") no-repeat center left 27px;
      transition: all 0.25s ease; }
      .calculator form .col_right input[type="text"]:nth-of-type(2):hover, .calculator form .col_right input[type="text"]:nth-of-type(2):focus {
        border-color: #f7b814; }
    .calculator form .col_right input[type="text"]:nth-of-type(1) {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 30px;
      padding-top: 14px;
      padding-bottom: 16px;
      padding-left: 52px;
      font-size: 18px;
      color: rgba(32, 32, 32, 0.8);
      outline: none !important;
      border: 2px solid #cccccc;
      border-radius: 60px;
      background: #ffffff url("../img/icons/man.png") no-repeat center left 27px;
      transition: all 0.25s ease; }
      .calculator form .col_right input[type="text"]:nth-of-type(1):hover, .calculator form .col_right input[type="text"]:nth-of-type(1):focus {
        border-color: #f7b814; }
    .calculator form .col_right button[type="submit"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 21px;
      padding-top: 4px;
      font-size: 18px;
      letter-spacing: 1.3px;
      color: #202020;
      text-transform: uppercase;
      line-height: 56px;
      outline: none;
      border: none;
      border-radius: 60px;
      background: linear-gradient(135deg, #ffcc3e 0%, #f7b714 100%);
      box-shadow: 0 3px 0 0 #d19e19;
      cursor: pointer;
      transition: all 0.25s ease; }
      .calculator form .col_right button[type="submit"]:hover {
        background: linear-gradient(135deg, #f7b714 0%, #ffcc3e 100%); }
    .calculator form .col_right .chekbox_wrap {
      margin-top: 20px;
      padding-left: 50px;
      padding-right: 50px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start; }
      .calculator form .col_right .chekbox_wrap .ui-state-default, .calculator form .col_right .chekbox_wrap .ui-widget-content .ui-state-default, .calculator form .col_right .chekbox_wrap .ui-widget-header .ui-state-default, .calculator form .col_right .chekbox_wrap .ui-button, .calculator form .col_right .chekbox_wrap html .ui-button.ui-state-disabled:hover, .calculator form .col_right .chekbox_wrap html .ui-button.ui-state-disabled:active {
        border: 0;
        background: none;
        margin-bottom: 0;
        margin-right: 10px; }
      .calculator form .col_right .chekbox_wrap .ui-button .ui-icon {
        background: url("../img/icons/checked_white.png") no-repeat center;
        transition: all 0.25s ease; }
      .calculator form .col_right .chekbox_wrap .ui-icon {
        height: 18px;
        width: 18px;
        margin-top: 0; }
      .calculator form .col_right .chekbox_wrap .ui-checkboxradio-label .ui-icon-background {
        box-shadow: none;
        border: 1px solid #FFDD69;
        border-radius: 4px; }
      .calculator form .col_right .chekbox_wrap .ui-state-active .ui-icon, .calculator form .col_right .chekbox_wrap .ui-button:active .ui-icon {
        background-color: #FFDD69; }
      .calculator form .col_right .chekbox_wrap .ui-button {
        padding: 0;
        outline: 0;
        box-shadow: none; }
      .calculator form .col_right .chekbox_wrap .checkbox_span {
        font-size: 15px;
        line-height: 18px;
        color: #606060; }
        .calculator form .col_right .chekbox_wrap .checkbox_span a {
          font-size: 15px;
          line-height: 18px;
          color: #606060;
          text-decoration: none;
          border-bottom: 1px solid rgba(96, 96, 96, 0.4);
          transition: all 0.25s ease; }
          .calculator form .col_right .chekbox_wrap .checkbox_span a:hover {
            border-bottom: 1px solid transparent;
            color: #f7b814;
            text-decoration: none; }

.ui-selectmenu-menu .ui-menu {
  border-radius: 10px;
  border: 2px solid #f7b814; }

.ui-state-active, .ui-widget-content .ui-state-active {
  background-color: #f7b814;
  border: 0; }

.ui-menu .ui-menu-item-wrapper {
  padding-left: 26px; }

.ui-widget {
  font-family: 'Cuprum', sans-serif; }

.our_work > .container {
  position: relative;
  padding-top: 110px;
  padding-bottom: 20px; }
  .our_work > .container:after {
    content: "";
    position: absolute;
    display: block;
    height: 150px;
    width: 3px;
    background-color: #eaeaea;
    top: 0;
    right: 5px; }
  .our_work > .container .vertical_right {
    top: 309px;
    right: -34px; }
  .our_work > .container > .row > div:nth-of-type(odd) .work_wrap {
    margin-left: 67px; }

.our_work h2 {
  margin-bottom: 84px;
  padding-left: 67px;
  padding-right: 67px;
  font-size: 54px;
  line-height: 62px;
  color: #606060; }
  .our_work h2 b {
    font-weight: 700;
    color: #f7b814; }

.our_work .work_wrap {
  max-width: 420px;
  margin-bottom: 80px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.1); }

.our_work .img_wrap {
  position: relative;
  border-bottom: 5px solid #ffdd69; }
  .our_work .img_wrap .logo {
    position: absolute;
    bottom: 6px;
    right: 10px; }

.our_work .work_desc {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 8px;
  padding-bottom: 8px; }
  .our_work .work_desc > div {
    margin-bottom: 13px; }
  .our_work .work_desc div:nth-child(odd) {
    font-size: 16px;
    line-height: 17px;
    color: #606060; }
    .our_work .work_desc div:nth-child(odd) img {
      margin-right: 11px; }
    .our_work .work_desc div:nth-child(odd) b {
      font-size: 18px;
      font-weight: 400;
      color: #202020; }
  .our_work .work_desc div:nth-child(even) {
    font-size: 16px;
    line-height: 20px;
    color: #202020; }
    .our_work .work_desc div:nth-child(even) span {
      padding-left: 10px; }
  .our_work .work_desc div:nth-of-type(1) img {
    margin-right: 13px;
    padding-left: 2px; }
  .our_work .work_desc div:nth-of-type(9) span:last-of-type {
    padding-left: 28px; }
  .our_work .work_desc div:last-of-type {
    font-size: 18px;
    font-weight: 700; }
    .our_work .work_desc div:last-of-type b {
      font-size: 22px; }

.personal_offer {
  background: url("../img/personal_offer_bg.jpg") no-repeat center;
  background-size: cover; }
  .personal_offer > .container {
    position: relative;
    padding-top: 126px;
    padding-bottom: 126px; }
    .personal_offer > .container:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: rgba(255, 255, 255, 0.5);
      top: 0;
      left: -7px; }
  .personal_offer .vertical_left {
    top: 322px;
    left: -58px; }

.personal_offer_wrap {
  max-width: 710px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 35px 1px rgba(0, 0, 0, 0.12); }

.in_personal_offer {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 30px;
  border: 2px dashed rgba(255, 221, 105, 0.5);
  border-radius: 10px; }
  .in_personal_offer .col-left h3 {
    margin-bottom: 31px;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    color: #202020; }
  .in_personal_offer .col-left ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none; }
    .in_personal_offer .col-left ul li {
      position: relative;
      margin-bottom: 17px;
      padding-left: 25px;
      padding-right: 43px;
      font-size: 16px;
      line-height: 20px;
      color: rgba(32, 32, 32, 0.85); }
      .in_personal_offer .col-left ul li:last-of-type {
        margin-bottom: 0; }
      .in_personal_offer .col-left ul li:nth-of-type(1):before {
        content: "1."; }
      .in_personal_offer .col-left ul li:nth-of-type(2):before {
        content: "2."; }
      .in_personal_offer .col-left ul li:nth-of-type(3):before {
        content: "3."; }
      .in_personal_offer .col-left ul li:before {
        position: absolute;
        left: 2px;
        top: -3px;
        font-size: 24px;
        font-weight: 700;
        color: #f7b814; }
  .in_personal_offer form {
    position: relative;
    padding-top: 86px;
    background: url("../img/icons/personal_offer_arrow.png") no-repeat left 13px top 18px, url("../img/icons/personal_offer_ico.png") no-repeat top 6px right 28px; }
    .in_personal_offer form input[type="text"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 30px;
      padding-top: 14px;
      padding-bottom: 16px;
      padding-left: 52px;
      font-size: 18px;
      color: rgba(32, 32, 32, 0.8);
      outline: none;
      border: 2px solid #cccccc;
      border-radius: 60px;
      background: url("../img/icons/input_phone.png") no-repeat left 27px center;
      transition: all 0.25s ease;
      background-color: #ffffff; }
      .in_personal_offer form input[type="text"]:hover, .in_personal_offer form input[type="text"]:focus {
        border-color: #f7b814; }
    .in_personal_offer form button[type="submit"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 26px;
      font-size: 18px;
      letter-spacing: 1.3px;
      color: #202020;
      text-transform: uppercase;
      line-height: 58px;
      outline: none;
      border: none;
      border-radius: 60px;
      background: linear-gradient(135deg, #ffcc3e 0%, #f7b714 100%);
      box-shadow: 0 3px 0 0 #d19e19;
      cursor: pointer;
      transition: all 0.25s ease; }
      .in_personal_offer form button[type="submit"]:hover {
        background: linear-gradient(135deg, #f7b714 0%, #ffcc3e 100%); }
    .in_personal_offer form .chekbox_wrap {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start; }
      .in_personal_offer form .chekbox_wrap .ui-state-default, .in_personal_offer form .chekbox_wrap .ui-widget-content .ui-state-default, .in_personal_offer form .chekbox_wrap .ui-widget-header .ui-state-default, .in_personal_offer form .chekbox_wrap .ui-button, .in_personal_offer form .chekbox_wrap html .ui-button.ui-state-disabled:hover, .in_personal_offer form .chekbox_wrap html .ui-button.ui-state-disabled:active {
        border: 0;
        background: none;
        margin-bottom: 0;
        margin-right: 10px; }
      .in_personal_offer form .chekbox_wrap .ui-button .ui-icon {
        background: url("../img/icons/checked_white.png") no-repeat center;
        transition: all 0.25s ease; }
      .in_personal_offer form .chekbox_wrap .ui-icon {
        height: 18px;
        width: 18px;
        margin-top: 0; }
      .in_personal_offer form .chekbox_wrap .ui-checkboxradio-label .ui-icon-background {
        box-shadow: none;
        border: 1px solid #FFDD69;
        border-radius: 4px;
        background-color: #ffffff; }
      .in_personal_offer form .chekbox_wrap .ui-state-active .ui-icon, .in_personal_offer form .chekbox_wrap .ui-button:active .ui-icon {
        background-color: #FFDD69; }
      .in_personal_offer form .chekbox_wrap .ui-button {
        padding: 0;
        outline: 0;
        box-shadow: none; }
      .in_personal_offer form .chekbox_wrap .checkbox_span {
        font-size: 16px;
        line-height: 20px;
        color: #606060; }
        .in_personal_offer form .chekbox_wrap .checkbox_span a {
          font-size: 16px;
          line-height: 20px;
          color: #606060;
          text-decoration: none;
          border-bottom: 1px solid rgba(96, 96, 96, 0.4);
          transition: all 0.25s ease; }
          .in_personal_offer form .chekbox_wrap .checkbox_span a:hover {
            border-bottom: 1px solid transparent;
            color: #f7b814;
            text-decoration: none; }

.certificate {
  background: url("../img/certificate_bg_left.jpg") no-repeat left 20px top, url("../img/certificate_bg_right.jpg") no-repeat right 100px top; }
  .certificate > .container {
    position: relative;
    padding-top: 111px;
    padding-bottom: 150px; }
    .certificate > .container:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      right: 5px; }
  .certificate .vertical_right {
    top: 304px;
    right: -29px; }
  .certificate h2 {
    margin-bottom: 82px;
    padding-left: 67px;
    padding-right: 67px;
    font-size: 54px;
    line-height: 62px;
    color: #606060; }
    .certificate h2 b {
      font-weight: 700;
      color: #f7b814; }

.certificate_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 67px;
  margin-right: 67px; }

.partners {
  background: url("../img/calculator_bg.jpg") center;
  background-size: cover; }
  .partners > .container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 77px; }
    .partners > .container:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      left: -7px; }
  .partners .vertical_left {
    top: 322px;
    left: -53px;
    color: rgba(32, 32, 32, 0.5); }
    .partners .vertical_left:after {
      background-color: #eaeaea; }
    .partners .vertical_left:before {
      background-color: #eaeaea; }
  .partners h2 {
    margin-bottom: 21px;
    padding-left: 67px;
    font-size: 54px;
    font-weight: 700;
    line-height: 62px;
    color: #f7b814; }
  .partners h5 {
    max-width: 520px;
    margin-bottom: 51px;
    padding-left: 67px;
    font-size: 24px;
    line-height: 30px;
    color: #202020; }
  .partners .partners_wrap {
    margin-left: 67px;
    margin-right: 67px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .partners .partner {
    max-width: 163px;
    width: 100%; }
    .partners .partner:nth-child(even) {
      padding-top: 50px; }
    .partners .partner img {
      margin-bottom: 16px; }
    .partners .partner p {
      font-size: 18px;
      line-height: 24px;
      color: rgba(32, 32, 32, 0.8); }

.manufacture > .container {
  padding-top: 108px;
  padding-bottom: 100px;
  position: relative; }

.manufacture h2 {
  margin-bottom: 20px;
  padding-left: 67px;
  padding-right: 67px;
  font-size: 54px;
  font-weight: 700;
  line-height: 62px;
  color: #f7b814; }

.manufacture h5 {
  max-width: 536px;
  margin-bottom: 99px;
  padding-left: 67px;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: #202020; }

.manufacture .manufacture_img_wrap {
  margin-left: 67px;
  margin-right: 67px; }
  .manufacture .manufacture_img_wrap img {
    border-radius: 6px;
    box-shadow: 0 10px 35px 1px rgba(0, 0, 0, 0.1); }

.manufacture .map_wrap {
  position: relative;
  margin-top: 150px; }
  .manufacture .map_wrap img {
    border-radius: 10px; }
  .manufacture .map_wrap .address {
    position: absolute;
    top: 232px;
    left: 230px;
    max-width: 295px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.15); }
  .manufacture .map_wrap .in_address {
    padding-top: 16px;
    padding-left: 20px;
    border: 2px dashed #ffeeb4; }
    .manufacture .map_wrap .in_address h6 {
      margin-bottom: 5px;
      font-size: 24px;
      color: #202020;
      line-height: 30px; }
    .manufacture .map_wrap .in_address p {
      font-size: 18px;
      line-height: 20px;
      color: #202020; }

footer {
  padding-top: 30px;
  padding-bottom: 42px;
  background-color: #202020; }
  footer hr {
    margin-top: 0;
    margin-bottom: 0;
    border-top: 2px solid rgba(255, 255, 255, 0.2); }
  footer .top_btn {
    display: block;
    max-width: 180px;
    width: 100%;
    margin-bottom: 34px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4px;
    font-size: 18px;
    letter-spacing: 1.3px;
    color: #202020;
    text-transform: uppercase;
    line-height: 56px;
    outline: none;
    border: none;
    border-radius: 60px;
    background: linear-gradient(135deg, #ffcc3e 0%, #f7b714 100%);
    box-shadow: 0 3px 0 0 #d19e19;
    cursor: pointer;
    transition: all 0.25s ease;
    text-align: center;
    text-decoration: none !important; }
    footer .top_btn:hover {
      background: linear-gradient(135deg, #f7b714 0%, #ffcc3e 100%); }
  footer .footer_nav_row {
    padding-top: 36px;
    align-items: center; }
  footer ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none; }
    footer ul li {
      margin-right: 45px; }
      footer ul li:last-of-type {
        margin-right: 0; }
      footer ul li a {
        font-size: 18px;
        color: #ffffff;
        text-decoration: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.35);
        transition: all 0.25s ease; }
        footer ul li a:hover, footer ul li a.active {
          color: #f7b814;
          border-color: rgba(247, 184, 20, 0.75);
          text-decoration: none; }
  footer .phone_col {
    padding-left: 40px; }
    footer .phone_col span {
      padding-left: 32px;
      font-size: 16px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.8); }
    footer .phone_col p {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 32px;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      color: #f8be26;
      background: url("../img/icons/orange_phone.png") no-repeat left center; }
  footer .footer_mail {
    margin-top: 13px;
    padding-bottom: 31px;
    text-align: right; }
    footer .footer_mail img {
      margin-right: 12px; }
    footer .footer_mail a {
      font-size: 18px;
      color: #ffffff;
      text-decoration: none;
      transition: all 0.25s ease; }
      footer .footer_mail a:hover {
        color: #f7b814;
        text-decoration: none; }

.footer_bottom_row {
  padding-top: 38px;
  align-items: center;
  font-size: 18px;
  color: #ffffff; }
  .footer_bottom_row div:nth-of-type(1) {
    text-align: left; }
  .footer_bottom_row div:nth-of-type(2) {
    text-align: center; }
    .footer_bottom_row div:nth-of-type(2) a {
      font-size: 18px;
      color: #ffffff;
      text-decoration: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.35);
      transition: all 0.25s ease; }
      .footer_bottom_row div:nth-of-type(2) a:hover {
        color: #f7b814;
        border-bottom: 1px solid transparent; }
  .footer_bottom_row div:nth-of-type(3) {
    text-align: right; }

.thanks {
  background: url("../img/thanks_bg.jpg") no-repeat center;
  background-size: cover; }
  .thanks > .container {
    padding-top: 174px;
    padding-bottom: 133px; }
  .thanks h1 {
    margin-bottom: 30px;
    font-size: 54px;
    color: #ffffff;
    text-align: center; }
  .thanks h2 {
    margin-bottom: 83px;
    font-size: 24px;
    color: #ffffff; }
  .thanks p {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 42px;
    font-size: 24px;
    line-height: 30px;
    color: #ffffff; }
  .thanks a {
    position: relative;
    display: block;
    max-width: 380px;
    margin-left: auto;
    margin-right: auto; }
    .thanks a img {
      border-radius: 10px; }
    .thanks a span {
      display: none;
      position: absolute;
      max-width: 160px;
      width: 100%;
      margin-left: 50%;
      left: -80px;
      top: 50%;
      margin-top: -20px;
      padding-top: 4px;
      font-size: 16px;
      color: #202020;
      text-transform: uppercase;
      line-height: 40px;
      outline: none;
      border: none;
      border-radius: 60px;
      background: linear-gradient(135deg, #ffcc3e 0%, #f7b714 100%);
      box-shadow: 0 3px 0 0 #d19e19;
      cursor: pointer;
      transition: all 0.25s ease;
      text-align: center;
      text-decoration: none !important; }
      .thanks a span:hover {
        background: linear-gradient(135deg, #f7b714 0%, #ffcc3e 100%); }

.privacy_header {
  background-color: rgba(32, 32, 32, 0.8); }

.privacy {
  padding-top: 200px;
  padding-bottom: 110px; }
  .privacy h1 {
    margin-bottom: 30px;
    font-size: 46px;
    color: #202020; }
    .privacy h1 b {
      color: #f7b814; }
  .privacy h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 38px; }
  .privacy p {
    text-indent: 60px;
    font-size: 16px;
    text-align: justify; }
  .privacy ul {
    margin-bottom: 30px;
    padding-left: 60px; }
    .privacy ul li {
      margin-bottom: 15px;
      font-size: 16px;
      color: #f7b814;
      text-align: justify; }
      .privacy ul li span {
        color: #202020; }
  .privacy a {
    color: #f7b814;
    text-decoration: none;
    border-bottom: 1px solid #f7b814;
    transition: all 0.25s ease; }
    .privacy a:hover {
      border-bottom: 1px solid transparent; }
