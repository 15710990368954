@import url('https://fonts.googleapis.com/css?family=Cuprum:400,400i,700,700i&subset=cyrillic');

$font-cuprum: 'Cuprum', sans-serif;
$trans: all .25s ease;

//colors
$orange: #f7b814;
$black: #202020;
$gray: rgba(32, 32, 32, .85);
$l_gray: #606060;
$white: #ffffff;
$l_white: rgba(255, 255, 255, .5);

body {
  font-family: $font-cuprum;
  font-weight: 400;
  font-size: 16px;
}

//HEADER

header {
  position: fixed;
  width: 100%;
  z-index: 100;

  .row {
    position: relative;
    align-items: center;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      margin-right: 45px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        font-size: 18px;
        color: $white;
        text-decoration: none;
        border-bottom: 1px solid rgba(255, 255, 255, .35);
        transition: $trans;

        &:hover, &.mPS2id-highlight {
          color: $orange;
          border-color: rgba(247, 184, 20, .75);
          text-decoration: none;
        }
      }
    }
  }

  .phone_col {
    padding-left: 40px;

    span {
      padding-left: 32px;
      font-size: 16px;
      line-height: 16px;
      color: rgba(255, 255, 255, .8);

      span {
        padding-left: 0;
      }
    }

    p {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 32px;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      color: #f8be26 !important;
      background: url("../img/icons/orange_phone.png") no-repeat left center;
    }
  }
}

.scroll_header {
  background-color: $gray;
}

//MAIN SCREEN

.main_screen {
  background: url("../img/main_screen_bg.jpg") no-repeat center;
  background-size: cover;

  & > .container {
    position: relative;
    padding-top: 129px;
    padding-bottom: 55px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: $l_white;
      top: 0;
      left: 3px;
    }
  }

  .col_left {
    padding-top: 41px;
  }

  h1 {
    margin-bottom: 32px;
    font-size: 54px;
    font-weight: 700;
    line-height: 62px;
    color: $white;
  }

  h4 {
    max-width: 340px;
    margin-bottom: 68px;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: $white;
  }

  .main_screen_desc {
    padding-top: 40px;
    padding-left: 67px;
    padding-right: 67px;

    .item {
      margin-bottom: 52px;
      padding-left: 83px;
      font-size: 18px;
      line-height: 24px;
      color: $white;

      &:nth-of-type(1) {
        background: url("../img/icons/main_screen1.png") no-repeat left 15px center;
      }

      &:nth-of-type(2) {
        background: url("../img/icons/main_screen2.png") no-repeat left 15px center;
      }

      &:nth-of-type(3) {
        background: url("../img/icons/main_screen3.png") no-repeat left 15px center;
      }

      &:nth-of-type(4) {
        background: url("../img/icons/main_screen4.png") no-repeat left 15px center;
      }
    }
  }

  .col_right {
    padding-right: 78px;
  }

  form {
    padding-top: 36px;
    padding-bottom: 25px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 10px 12px 1px rgba(0, 0, 0, 0.12);

    h3 {
      margin-bottom: 32px;
      padding-left: 48px;
      padding-right: 42px;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: $black;
      text-align: center;
    }

    p {
      position: relative;
      padding-left: 66px;
      padding-right: 58px;
      font-size: 16px;
      line-height: 20px;
      color: $gray;

      &:before {
        position: absolute;
        top: -2px;
        left: 44px;
        font-size: 24px;
        font-weight: 700;
        color: $orange;
      }

      &:nth-of-type(1):before {
        content: '1.';
      }

      &:nth-of-type(2):before {
        content: '2.';
      }

      &:nth-of-type(3):before {
        content: '3.';
      }
    }

    input[type="text"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 14px;
      padding-bottom: 16px;
      padding-left: 52px;
      font-size: 18px;
      color: rgba(32, 32, 32, .8);
      outline: none;
      border: 2px solid #cccccc;
      border-radius: 60px;
      background: url("../img/icons/input_phone.png") no-repeat left 27px center;
      transition: $trans;

      &:hover, &:focus {
        border-color: $orange;
      }
    }

    button[type="submit"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 34px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 4px;
      font-size: 18px;
      letter-spacing: 1.3px;
      color: $black;
      text-transform: uppercase;
      line-height: 56px;
      outline: none;
      border: none;
      border-radius: 60px;
      background: linear-gradient(135deg, rgba(255, 204, 62, 1) 0%, rgba(247, 183, 20, 1) 100%);
      box-shadow: 0 3px 0 0 #d19e19;
      cursor: pointer;
      transition: $trans;

      &:hover {
        background: linear-gradient(135deg, rgba(247, 183, 20, 1) 0%, rgba(255, 204, 62, 1) 100%);
      }
    }
  }

  .chekbox_wrap {
    margin-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
      border: 0;
      background: none;
      margin-bottom: 0;
      margin-right: 10px;
    }

    .ui-button .ui-icon {
      background: url("../img/icons/checked_white.png") no-repeat center;
      transition: $trans;
    }

    .ui-icon {
      height: 18px;
      width: 18px;
      margin-top: 0;
    }

    .ui-checkboxradio-label .ui-icon-background {
      box-shadow: none;
      border: 1px solid #FFDD69;
      border-radius: 4px;
    }

    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background-color: #FFDD69;
    }

    .ui-button {
      padding: 0;
      outline: 0;
      box-shadow: none;
    }

    .checkbox_span {
      font-size: 15px;
      line-height: 18px;
      color: $l_gray;

      a {
        font-size: 15px;
        line-height: 18px;
        color: $l_gray;
        text-decoration: none;
        border-bottom: 1px solid rgba(96, 96, 96, .4);
        transition: $trans;

        &:hover {
          border-bottom: 1px solid transparent;
          color: $orange;
          text-decoration: none;
        }
      }
    }
  }
}

.vertical_left {
  display: inline-block;
  position: absolute;
  top: 294px;
  left: -19px;
  font-size: 14px;
  color: $l_white;
  text-transform: uppercase;
  transform: rotate(270deg);

  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 50px;
    top: 10px;
    left: -80px;
    background-color: $l_white;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 50px;
    right: -80px;
    bottom: 10px;
    background-color: $l_white;
  }
}

.vertical_right {
  display: inline-block;
  position: absolute;
  top: 205px;
  right: -38px;
  font-size: 14px;
  color: rgba(32,32,32,.5);
  text-transform: uppercase;
  transform: rotate(270deg);

  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 50px;
    top: 10px;
    left: -80px;
    background-color: #eaeaea;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 50px;
    right: -80px;
    bottom: 10px;
    background-color: #eaeaea;
  }
}

//ADVANTAGES

.advantages {
  padding-top: 110px;
  padding-bottom: 100px;
  background: url("../img/advantages_left_bg.jpg") no-repeat left center,
  url("../img/advantages_right_bg.jpg") no-repeat right bottom 70px;

  & > .container {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: -108px;
      right: 5px;
    }
  }

  h2 {
    margin-bottom: 84px;
    padding-left: 67px;
    font-size: 54px;
    line-height: 62px;
    color: $l_gray;

    b {
      font-weight: 700;
      color: $orange;
    }
  }

  .col_left {
    margin-bottom: 50px;
    padding-left: 82px;

    &:nth-of-type(2) {
      padding-top: 82px;
    }
  }

  .col_right {
    margin-bottom: 50px;
    padding-right: 82px;

    &:nth-of-type(4) {
      padding-top: 82px;
    }

    .advantages_item_wrap {
      margin-left: auto;
    }
  }

  .advantages_item_wrap {
    position: relative;
    max-width: 323px;
    padding-top: 25px;
    box-shadow: 0 10px 35px 1px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    background-color: $white;

    .num {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 24px;
      font-weight: 700;
      line-height: 40px;
      width: 40px;
      text-align: center;
      color: $white;
      background-color: $orange;
      border-radius: 50%;
    }

    h4 {
      margin-bottom: 2px;
      padding-right: 20px;
      padding-left: 80px;
      font-size: 24px;
      line-height: 30px;
      color: $black;
    }

    p {
      margin-bottom: 0;
      padding-left: 80px;
      padding-right: 20px;
      font-size: 18px;
      line-height: 30px;
      color: $black;
    }

    img {
      margin-top: 42px;
      border-radius: 0 0 10px 10px;
    }
  }
}

//FREE CALCULATION

.free_calculation {
  background: url("../img/free_calculation_bg1.jpg") no-repeat center;
  background-size: cover;

  .vertical_left {
    top: 321px;
    left: -57px;
  }

  &>.container {
    position: relative;
    padding-top: 93px;
    padding-bottom: 91px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: $l_white;
      top: 0;
      left: -7px;
    }
  }

  .calculation_form_wrap {
    max-width: 460px;
    margin-left: 164px;
  }

  h2 {
    margin-bottom: 23px;
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;
    color: $white;
  }

  p {
    font-size: 24px;
    line-height: 30px;
    color: $white;

    &:last-of-type {
      margin-bottom: 26px;
    }
  }

  form {

    input[type="text"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 30px;
      padding-top: 14px;
      padding-bottom: 16px;
      padding-left: 52px;
      font-size: 18px;
      color: rgba(32, 32, 32, .8);
      outline: none;
      border: 2px solid transparent;
      border-radius: 60px;
      background: url("../img/icons/input_phone.png") no-repeat left 27px center;
      transition: $trans;
      background-color: $white;

      &:hover, &:focus {
        border-color: $orange;
      }
    }

    button[type="submit"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 34px;
      padding-top: 4px;
      font-size: 18px;
      letter-spacing: 1.3px;
      color: $black;
      text-transform: uppercase;
      line-height: 56px;
      outline: none;
      border: none;
      border-radius: 60px;
      background: linear-gradient(135deg, rgba(255, 204, 62, 1) 0%, rgba(247, 183, 20, 1) 100%);
      box-shadow: 0 3px 0 0 #d19e19;
      cursor: pointer;
      transition: $trans;

      &:hover {
        background: linear-gradient(135deg, rgba(247, 183, 20, 1) 0%, rgba(255, 204, 62, 1) 100%);
      }
    }

    .chekbox_wrap {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
        border: 0;
        background: none;
        margin-bottom: 0;
        margin-right: 10px;
      }

      .ui-button .ui-icon {
        background: url("../img/icons/checked_white.png") no-repeat center;
        transition: $trans;
      }

      .ui-icon {
        height: 18px;
        width: 18px;
        margin-top: 0;
      }

      .ui-checkboxradio-label .ui-icon-background {
        box-shadow: none;
        border: 1px solid #FFDD69;
        border-radius: 4px;
        background-color: $white;
      }

      .ui-state-active .ui-icon, .ui-button:active .ui-icon {
        background-color: #FFDD69;
      }

      .ui-button {
        padding: 0;
        outline: 0;
        box-shadow: none;
      }

      .checkbox_span {
        font-size: 18px;
        line-height: 20px;
        color: $white;

        a {
          font-size: 18px;
          line-height: 20px;
          color: $white;
          text-decoration: none;
          border-bottom: 1px solid rgba(255, 255, 255, .4);
          transition: $trans;

          &:hover {
            border-bottom: 1px solid transparent;
            color: $orange;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.free_calculation_2 {
  background: url("../img/free_calculation_bg2.jpg") no-repeat center;
  background-size: cover;
}

.free_calculation_3 {
  background: url("../img/free_calculation_bg3.jpg") no-repeat center;
  background-size: cover;
}

.free_calculation_4 {
  background: url("../img/free_calculation_bg4.jpg") no-repeat center;
  background-size: cover;
}

.free_calculation_5 {
  background: url("../img/free_calculation_bg5.jpg") no-repeat center;
  background-size: cover;
}

.free_calculation_6 {
  background: url("../img/free_calculation_bg6.jpg") no-repeat center;
  background-size: cover;
}

.free_calculation_7 {
  background: url("../img/free_calculation_bg7.jpg") no-repeat center;
  background-size: cover;
}

.free_calculation_8 {
  background: url("../img/free_calculation_bg8.jpg") no-repeat center;
  background-size: cover;
}

.free_calculation_9 {
  background: url("../img/free_calculation_bg9.jpg") no-repeat center;
  background-size: cover;
}

.free_calculation_10 {
  background: url("../img/free_calculation_bg10.jpg") no-repeat center;
  background-size: cover;
}

//WORK EXAMPLES

.work_examples {

  &>.container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 120px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      right: 5px;
    }
  }

  .vertical_right {
    top: 315px;
    right: -40px;
  }

  h2 {
    margin-bottom: 16px;
    padding-left: 67px;
    padding-right: 67px;
    font-size: 54px;
    line-height: 60px;
    color: $l_gray;

    b {
      font-weight: 700;
      color: $orange;
    }
  }

  p {
    padding-left: 67px;
    padding-right: 67px;
    font-size: 24px;
    color: $black;

    &:last-of-type {
      margin-bottom: 89px;
    }
  }

}

.examples_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 67px;
  padding-right: 67px;
}

.item_290 {
  max-width: 290px;
}

.item_365 {
  max-width: 365px;
}

.example_item {
  margin-bottom: 30px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0,0,0,0.1);

  .example_logo {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .example_price {
    position: absolute;
    left: 24px;
    bottom: 11px;

    p {
      margin-bottom: 12px;
      padding-right: 0;
      padding-left: 0;
      font-size: 18px;
      line-height: 16px;
      color: $white;
    }
  }
}

//INDIVIDUAL PROJECT

.individual_project {
  padding-top: 10px;
  padding-bottom: 104px;
}

.individual_project_wrap {
  margin-left: 67px;
  margin-right: 67px;
  padding: 20px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 5px 20px 1px rgba(0,0,0,0.1);
}

.in_ind_project {
  position: relative;
  padding-top: 34px;
  padding-bottom: 24px;
  border: 2px dashed rgba(255,221,105,.5);
  border-radius: 10px;

  img {
    position: absolute;
    left: 45px;
    bottom: -16px;
  }

  h3 {
    margin-bottom: 15px;
    padding-left: 67px;
    font-size: 36px;
    color: $black;
  }

  p {
    padding-left: 67px;
    padding-right: 240px;
    font-size: 18px;
    line-height: 24px;
    color: $black;
    background: url("../img/icons/individual_project_ico.png") no-repeat right 48px center;
  }
}

//HEADSETS TYPES

.headsets_types {
  background: url("../img/headsets_bg_left.jpg") no-repeat left bottom 30px ;

  .vertical_right {
    top: 324px;
    right: -47px;
  }

  &>.container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 85px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      right: 5px;
    }
  }

  h2 {
    margin-bottom: 86px;
    padding-left: 67px;
    padding-right: 67px;
    font-size: 54px;
    line-height: 62px;
    color: $l_gray;

    b {
      font-weight: 700;
      color: $orange;
    }
  }

  .headsets_types_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 67px;
    margin-right: 67px;
  }

  .headsets_item {
    position: relative;
    max-width: 292px;
    margin-bottom: 65px;
    border-radius: 10px;
    box-shadow: 0 10px 35px 1px rgba(0, 0, 0, 0.10);

    p {
      position: absolute;
      width: 100%;
      top: 32px;
      text-align: center;

      span {
        position: relative;
        font-size: 24px;
        color: $black;

        &:before {
          content: "";
          position: absolute;
          display: block;
          height: 8px;
          width: 8px;
          top: 9px;
          left: -18px;
          background-color: $orange;
          border-radius: 50%;
        }
      }
    }
  }
}

//WHEN ORDER

.when_order {
  background: url("../img/when_order_bg_left.jpg") no-repeat left center,
              url("../img/when_order_bg_right.jpg") no-repeat right center;
  background-size: auto 100%;

  &>.container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 150px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      right: 5px;
    }
  }

  .vertical_right {
    top: 292px;
    right: -16px;
  }

  h2 {
    margin-bottom: 84px;
    padding-left: 67px;
    font-size: 54px;
    line-height: 62px;
    color: $l_gray;

    b {
      font-weight: 700;
      color: $orange;
    }
  }
}

.when_order_left {
  margin-left: 67px;
  position: relative;

  .logo {
    position: absolute;
    right: 10px;
    bottom: 12px;
  }
}

.when_order_desc {
  position: relative;
  margin-top: 50px;
  margin-left: 18px;
  margin-right: 97px;
  padding-top: 28px;
  padding-bottom: 34px;
  padding-right: 42px;
  padding-left: 42px;
  font-size: 18px;
  line-height: 24px;
  color: $black;
  border: 8px solid #ffdd69;

  &:before {
    content: '';
    position: absolute;
    top: 34px;
    left: -28px;
    border: 8px solid transparent;
    border-right: 12px solid #ffdd69;
  }
}

.when_order_ico {
  margin-top: 33px;
  text-align: right;

  img {
    margin-right: 97px;
  }
}

//COMPARE

.compare {
  background: url("../img/compare_bg_left.jpg") no-repeat left 39px top 109px,
              url("../img/compare_bg_right.jpg") no-repeat right 39px bottom 50px;

  &>.container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 73px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      right: 5px;
    }
  }

  .vertical_right {
    top: 306px;
    right: -31px;
  }

  h2 {
    padding-left: 67px;
    padding-right: 67px;
    margin-bottom: 84px;
    font-size: 54px;
    line-height: 62px;
    color: $l_gray;

    b {
      font-weight: 700;
      color: $orange;
    }
  }

  ul {
    margin-left: 66px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      padding-top: 18px;
      padding-right: 20px;
      padding-bottom: 22px;
      padding-left: 36px;
      position: relative;
      max-width: 293px;
      margin-bottom: 25px;
      border: 1px solid $white;
      border-radius: 10px;
      background-color: $white;
      box-shadow: 0 5px 25px 1px rgba(0,0,0,0.08);

      &:before {
        content: "«";
        position: absolute;
        left: -50px;
        top: 12px;
        font-size: 50px;
        color: $l_gray;
        transform: rotate(180deg);
      }

      p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 27px;
        color: $l_gray;

        &.price {
          font-weight: 700;
          color: $orange;
        }
      }

      &:last-of-type {
        border: 1px solid #ffdd69;
        background: $white url("../img/icons/compare_coin.png") no-repeat right 30px center;

        p {
          font-size: 22px;
          line-height: 36px;
          color: $black;

          &.price {
            font-size: 32px;
            color: $orange;
          }
        }

        &:before {
          top: 17px;
          color: $orange;
        }
      }
    }
  }
}

.compare_left {
  max-width: 585px;
  margin-left: 67px;

  .img_wrap {
    margin-bottom: 26px;
    position: relative;

    .logo {
      position: absolute;
      right: 11px;
      bottom: 12px;
    }
  }

  p {
    padding-left: 30px;
    max-width: 300px;
    font-size: 18px;
    line-height: 24px;
    color: $l_gray;

    b {
      font-weight: 700;
      color: $black;
    }
  }
}

//CALCULATOR
.calculator {
  background: url("../img/calculator_bg.jpg") center;
  background-size: cover;

  &>.container {
    position: relative;
    padding-top: 120px;
    padding-bottom: 107px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      left: -7px;
    }
  }

  .vertical_left {
    top: 322px;
    left: -57px;
    color: rgba(32,32,32,.5);

    &:after {
      background-color: #eaeaea;
    }

    &:before {
      background-color: #eaeaea;
    }
  }

  h2 {
    padding-left: 67px;
    padding-right: 67px;
    margin-bottom: 82px;
    font-size: 54px;
    font-weight: 700;
    color: $orange;
  }

  .img_wrap {
    position: relative;
    max-width: 322px;
    margin-left: 67px;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);

    img {
      border-radius: 10px;
    }

    p {
      position: absolute;
      top: 30px;
      left: 37px;
      max-width: 250px;
      font-size: 24px;
      color: $black;
      line-height: 30px;
    }
  }

  form {
    padding-top: 35px;
    padding-bottom: 25px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);

    h4 {
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 28px;
      color: $black;
      text-align: center;
    }

    .col_left {
      padding-left: 45px;

      .ui-selectmenu-button.ui-button {
        margin-bottom: 30px;
        max-width: 290px;
        width: 100%;
        padding: 0 0 0 26px;
        font-size: 18px;
        color: rgba(32, 32, 32, .8);;
        line-height: 60px;
        border: 2px solid #cccccc;
        border-radius: 60px;
        outline: none !important;
        background-color: $white;

        &:hover, &:active {
          border-color: $orange;
        }
      }

      .ui-button .ui-icon, .ui-state-active .ui-icon, .ui-button:active .ui-icon {
        position: relative;
        background: url("../img/icons/select_arrow.png") no-repeat center;
        top: 21px;
        right: 27px;
      }

      .calculator form .ui-button .ui-icon, .ui-selectmenu-button-open .ui-icon, .calculator form .ui-state-active .ui-icon, .calculator form .ui-button:active .ui-icon {
        transform: rotate(180deg) !important;
      }

      .ui-state-active .ui-icon, .ui-button:active .ui-icon {
        transform: rotate(180deg);
      }

      .ui-widget.ui-widget-content {
        border: 2px solid $orange;
        border-radius: 60px;
      }

      input[type="text"] {
        display: block;
        max-width: 290px;
        width: 100%;
        margin-bottom: 30px;
        padding-left: 26px;
        padding-top: 14px;
        padding-bottom: 16px;
        font-size: 18px;
        color: rgba(32, 32, 32, .8);
        outline: none !important;
        border: 2px solid #cccccc;
        border-radius: 60px;
        background-color: $white;
        transition: $trans;

        &:hover, &:focus {
          border-color: $orange;
        }
      }
    }

    .col_right {

      input[type="text"]:nth-of-type(2) {
        display: block;
        max-width: 290px;
        width: 100%;
        margin-bottom: 30px;
        padding-top: 14px;
        padding-bottom: 16px;
        padding-left: 52px;
        font-size: 18px;
        color: rgba(32, 32, 32, .8);
        outline: none !important;
        border: 2px solid #cccccc;
        border-radius: 60px;
        background: $white url("../img/icons/input_phone.png") no-repeat center left 27px;
        transition: $trans;

        &:hover, &:focus {
          border-color: $orange;
        }
      }

      input[type="text"]:nth-of-type(1){
        display: block;
        max-width: 290px;
        width: 100%;
        margin-bottom: 30px;
        padding-top: 14px;
        padding-bottom: 16px;
        padding-left: 52px;
        font-size: 18px;
        color: rgba(32, 32, 32, .8);
        outline: none !important;
        border: 2px solid #cccccc;
        border-radius: 60px;
        background: $white url("../img/icons/man.png") no-repeat center left 27px;
        transition: $trans;

        &:hover, &:focus {
          border-color: $orange;
        }
      }

      button[type="submit"] {
        display: block;
        max-width: 290px;
        width: 100%;
        margin-bottom: 21px;
        padding-top: 4px;
        font-size: 18px;
        letter-spacing: 1.3px;
        color: $black;
        text-transform: uppercase;
        line-height: 56px;
        outline: none;
        border: none;
        border-radius: 60px;
        background: linear-gradient(135deg, rgba(255, 204, 62, 1) 0%, rgba(247, 183, 20, 1) 100%);
        box-shadow: 0 3px 0 0 #d19e19;
        cursor: pointer;
        transition: $trans;

        &:hover {
          background: linear-gradient(135deg, rgba(247, 183, 20, 1) 0%, rgba(255, 204, 62, 1) 100%);
        }
      }

      .chekbox_wrap {
        margin-top: 20px;
        padding-left: 50px;
        padding-right: 50px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
          border: 0;
          background: none;
          margin-bottom: 0;
          margin-right: 10px;
        }

        .ui-button .ui-icon {
          background: url("../img/icons/checked_white.png") no-repeat center;
          transition: $trans;
        }

        .ui-icon {
          height: 18px;
          width: 18px;
          margin-top: 0;
        }

        .ui-checkboxradio-label .ui-icon-background {
          box-shadow: none;
          border: 1px solid #FFDD69;
          border-radius: 4px;
        }

        .ui-state-active .ui-icon, .ui-button:active .ui-icon {
          background-color: #FFDD69;
        }

        .ui-button {
          padding: 0;
          outline: 0;
          box-shadow: none;
        }

        .checkbox_span {
          font-size: 15px;
          line-height: 18px;
          color: $l_gray;

          a {
            font-size: 15px;
            line-height: 18px;
            color: $l_gray;
            text-decoration: none;
            border-bottom: 1px solid rgba(96, 96, 96, .4);
            transition: $trans;

            &:hover {
              border-bottom: 1px solid transparent;
              color: $orange;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

//UI SELECT FORM

.ui-selectmenu-menu .ui-menu {
  border-radius: 10px;
  border: 2px solid $orange;
}

.ui-state-active, .ui-widget-content .ui-state-active {
  background-color: $orange;
  border: 0;
}

.ui-menu .ui-menu-item-wrapper {
  padding-left: 26px;
}

.ui-widget {
  font-family: 'Cuprum', sans-serif;
}

//OUR WORK

.our_work {

  &>.container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 20px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      right: 5px;
    }

    .vertical_right {
      top: 309px;
      right: -34px;
    }

    &>.row>div:nth-of-type(odd){

      .work_wrap {
        margin-left: 67px;
      }
    }
  }

  h2 {
    margin-bottom: 84px;
    padding-left: 67px;
    padding-right: 67px;
    font-size: 54px;
    line-height: 62px;
    color: $l_gray;

    b {
      font-weight: 700;
      color: $orange;
    }
  }

  .work_wrap {
    max-width: 420px;
    margin-bottom: 80px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 10px 30px 1px rgba(0,0,0,0.1);
  }

  .img_wrap {
    position: relative;
    border-bottom: 5px solid #ffdd69;

    .logo {
      position: absolute;
      bottom: 6px;
      right: 10px;
    }
  }

  .work_desc {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 8px;
    padding-bottom: 8px;

    &>div {
      margin-bottom: 13px;
    }

    div:nth-child(odd){
      font-size: 16px;
      line-height: 17px;
      color: $l_gray;

      img {
        margin-right: 11px;
      }

      b {
        font-size: 18px;
        font-weight: 400;
        color: $black;
      }
    }

    div:nth-child(even){
      font-size: 16px;
      line-height: 20px;
      color: $black;

      span {
        padding-left: 10px;
      }
    }

    div:nth-of-type(1) {

      img {
        margin-right: 13px;
        padding-left: 2px;
      }
    }

    div:nth-of-type(9){

      span:last-of-type {
        padding-left: 28px;
      }
    }

    div:last-of-type {
      font-size: 18px;
      font-weight: 700;

      b {
        font-size: 22px;
      }
    }
  }
}

//PERSONAL OFFER

.personal_offer {
  background: url("../img/personal_offer_bg.jpg") no-repeat center;
  background-size: cover;

  &>.container {
    position: relative;
    padding-top: 126px;
    padding-bottom: 126px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: $l_white;
      top: 0;
      left: -7px;
    }
  }

  .vertical_left {
    top: 322px;
    left: -58px;
  }
}

.personal_offer_wrap {
  max-width: 710px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 10px 35px 1px rgba(0,0,0,0.12);
}

.in_personal_offer {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 30px;
  border: 2px dashed rgba(255,221,105,.5);
  border-radius: 10px;

  .col-left {

    h3 {
      margin-bottom: 31px;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: $black;
    }

    ul {
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;

      li {
        position: relative;
        margin-bottom: 17px;
        padding-left: 25px;
        padding-right: 43px;
        font-size: 16px;
        line-height: 20px;
        color: $gray;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:nth-of-type(1):before{
          content: "1.";
        }

        &:nth-of-type(2):before{
          content: "2.";
        }

        &:nth-of-type(3):before{
          content: "3.";
        }

        &:before {
          position: absolute;
          left: 2px;
          top: -3px;
          font-size: 24px;
          font-weight: 700;
          color: $orange;
        }
      }
    }
  }

  form {
    position: relative;
    padding-top: 86px;
    background: url("../img/icons/personal_offer_arrow.png") no-repeat left 13px top 18px,
                url("../img/icons/personal_offer_ico.png") no-repeat top 6px right 28px;

    input[type="text"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 30px;
      padding-top: 14px;
      padding-bottom: 16px;
      padding-left: 52px;
      font-size: 18px;
      color: rgba(32, 32, 32, .8);
      outline: none;
      border: 2px solid #cccccc;
      border-radius: 60px;
      background: url("../img/icons/input_phone.png") no-repeat left 27px center;
      transition: $trans;
      background-color: $white;

      &:hover, &:focus {
        border-color: $orange;
      }
    }

    button[type="submit"] {
      display: block;
      max-width: 290px;
      width: 100%;
      margin-bottom: 26px;
      font-size: 18px;
      letter-spacing: 1.3px;
      color: $black;
      text-transform: uppercase;
      line-height: 58px;
      outline: none;
      border: none;
      border-radius: 60px;
      background: linear-gradient(135deg, rgba(255, 204, 62, 1) 0%, rgba(247, 183, 20, 1) 100%);
      box-shadow: 0 3px 0 0 #d19e19;
      cursor: pointer;
      transition: $trans;

      &:hover {
        background: linear-gradient(135deg, rgba(247, 183, 20, 1) 0%, rgba(255, 204, 62, 1) 100%);
      }
    }

    .chekbox_wrap {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
        border: 0;
        background: none;
        margin-bottom: 0;
        margin-right: 10px;
      }

      .ui-button .ui-icon {
        background: url("../img/icons/checked_white.png") no-repeat center;
        transition: $trans;
      }

      .ui-icon {
        height: 18px;
        width: 18px;
        margin-top: 0;
      }

      .ui-checkboxradio-label .ui-icon-background {
        box-shadow: none;
        border: 1px solid #FFDD69;
        border-radius: 4px;
        background-color: $white;
      }

      .ui-state-active .ui-icon, .ui-button:active .ui-icon {
        background-color: #FFDD69;
      }

      .ui-button {
        padding: 0;
        outline: 0;
        box-shadow: none;
      }

      .checkbox_span {
        font-size: 16px;
        line-height: 20px;
        color: #606060;

        a {
          font-size: 16px;
          line-height: 20px;
          color: #606060;
          text-decoration: none;
          border-bottom: 1px solid rgba(96,96,96,.4);
          transition: $trans;

          &:hover {
            border-bottom: 1px solid transparent;
            color: $orange;
            text-decoration: none;
          }
        }
      }
    }
  }
}

//CERTIFICATE

.certificate {
  background: url("../img/certificate_bg_left.jpg") no-repeat left 20px top,
              url("../img/certificate_bg_right.jpg") no-repeat right 100px top;

  &>.container  {
    position: relative;
    padding-top: 111px;
    padding-bottom: 150px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      right: 5px;
    }
  }

  .vertical_right {
    top: 304px;
    right: -29px;
  }

  h2 {
    margin-bottom: 82px;
    padding-left: 67px;
    padding-right: 67px;
    font-size: 54px;
    line-height: 62px;
    color: $l_gray;

    b {
      font-weight: 700;
      color: $orange;
    }
  }
}

.certificate_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 67px;
  margin-right: 67px;
}

//PARTNERS

.partners {
  background: url("../img/calculator_bg.jpg") center;
  background-size: cover;

  &>.container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 77px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 150px;
      width: 3px;
      background-color: #eaeaea;
      top: 0;
      left: -7px;
    }
  }

  .vertical_left {
    top: 322px;
    left: -53px;
    color: rgba(32,32,32,.5);

    &:after {
      background-color: #eaeaea;
    }

    &:before {
      background-color: #eaeaea;
    }
  }

  h2 {
    margin-bottom: 21px;
    padding-left: 67px;
    font-size: 54px;
    font-weight: 700;
    line-height: 62px;
    color: $orange;
  }

  h5 {
    max-width: 520px;
    margin-bottom: 51px;
    padding-left: 67px;
    font-size: 24px;
    line-height: 30px;
    color: $black;
  }

  .partners_wrap {
    margin-left: 67px;
    margin-right: 67px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .partner {
    max-width: 163px;
    width: 100%;

    &:nth-child(even){
      padding-top: 50px;
    }

    img {
      margin-bottom: 16px;
    }

    p {
      font-size: 18px;
      line-height: 24px;
      color: rgba(32,32,32,.8);
    }
  }
}

//MANUFACTURE

.manufacture {

  &>.container {
    padding-top: 108px;
    padding-bottom: 100px;
    position: relative;
  }

  h2 {
    margin-bottom: 20px;
    padding-left: 67px;
    padding-right: 67px;
    font-size: 54px;
    font-weight: 700;
    line-height: 62px;
    color: $orange;
  }

  h5 {
    max-width: 536px;
    margin-bottom: 99px;
    padding-left: 67px;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: $black;
  }

  .manufacture_img_wrap {
    margin-left: 67px;
    margin-right: 67px;

    img {
      border-radius: 6px;
      box-shadow: 0 10px 35px 1px rgba(0,0,0,0.1);
    }
  }

  .map_wrap {
    position: relative;
    margin-top: 150px;

    img {
      border-radius: 10px;
    }

    .address {
      position: absolute;
      top: 232px;
      left: 230px;
      max-width: 295px;
      padding: 20px;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0 5px 20px 1px rgba(0,0,0,0.15);
    }

    .in_address {
      padding-top: 16px;
      padding-left: 20px;
      border: 2px dashed #ffeeb4;

      h6 {
        margin-bottom: 5px;
        font-size: 24px;
        color: $black;
        line-height: 30px;
      }

      p {
        font-size: 18px;
        line-height: 20px;
        color: $black;
      }
    }
  }
}

//FOOTER

footer {
  padding-top: 30px;
  padding-bottom: 42px;
  background-color: $black;

  hr {
    margin-top: 0;
    margin-bottom: 0;
    border-top: 2px solid rgba(255,255,255,.2);
  }

  .top_btn {
    display: block;
    max-width: 180px;
    width: 100%;
    margin-bottom: 34px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4px;
    font-size: 18px;
    letter-spacing: 1.3px;
    color: $black;
    text-transform: uppercase;
    line-height: 56px;
    outline: none;
    border: none;
    border-radius: 60px;
    background: linear-gradient(135deg, rgba(255, 204, 62, 1) 0%, rgba(247, 183, 20, 1) 100%);
    box-shadow: 0 3px 0 0 #d19e19;
    cursor: pointer;
    transition: $trans;
    text-align: center;
    text-decoration: none !important;

    &:hover {
      background: linear-gradient(135deg, rgba(247, 183, 20, 1) 0%, rgba(255, 204, 62, 1) 100%);
    }
  }

  .footer_nav_row {
    padding-top: 36px;
    align-items: center;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      margin-right: 45px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        font-size: 18px;
        color: $white;
        text-decoration: none;
        border-bottom: 1px solid rgba(255, 255, 255, .35);
        transition: $trans;

        &:hover, &.active {
          color: $orange;
          border-color: rgba(247, 184, 20, .75);
          text-decoration: none;
        }
      }
    }
  }

  .phone_col {
    padding-left: 40px;

    span {
      padding-left: 32px;
      font-size: 16px;
      line-height: 16px;
      color: rgba(255, 255, 255, .8);
    }

    p {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 32px;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      color: #f8be26;
      background: url("../img/icons/orange_phone.png") no-repeat left center;
    }
  }

  .footer_mail {
    margin-top: 13px;
    padding-bottom: 31px;
    text-align: right;

    img {
      margin-right: 12px;
    }

    a {
      font-size: 18px;
      color: $white;
      text-decoration: none;
      transition: $trans;

      &:hover {
        color: $orange;
        text-decoration: none;
      }
    }
  }
}

.footer_bottom_row {
  padding-top: 38px;
  align-items: center;
  font-size: 18px;
  color: $white;
  
  div:nth-of-type(1){
    text-align: left;
  }

  div:nth-of-type(2){
    text-align: center;

    a {
      font-size: 18px;
      color: $white;
      text-decoration: none;
      border-bottom: 1px solid rgba(255,255,255,.35);
      transition: $trans;

      &:hover {
        color: $orange;
        border-bottom: 1px solid transparent;
      }
    }
  }

  div:nth-of-type(3){
    text-align: right;
  }
}

//input:focus::-webkit-input-placeholder { color:transparent !important; }
//input:focus:-moz-placeholder { color:transparent !important; } /* FF 4-18 */
//input:focus::-moz-placeholder { color:transparent !important; } /* FF 19+ */
//input:focus:-ms-input-placeholder { color:transparent !important; } /* IE 10+ */


//THANKS PAGE

.thanks {
  background: url("../img/thanks_bg.jpg") no-repeat center;
  background-size: cover;

  &>.container {
    padding-top: 174px;
    padding-bottom: 133px;
  }

  h1 {
    margin-bottom: 30px;
    font-size: 54px;
    color: $white;
    text-align: center;
  }

  h2 {
    margin-bottom: 83px;
    font-size: 24px;
    color: $white;
  }

  p {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 42px;
    font-size: 24px;
    line-height: 30px;
    color: $white;
  }

  a {
    position: relative;
    display: block;
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;

    img {
      border-radius: 10px;
    }

    span {
      display: none;
      position: absolute;
      max-width: 160px;
      width: 100%;
      margin-left: 50%;
      left: -80px;
      top: 50%;
      margin-top: -20px;
      padding-top: 4px;
      font-size: 16px;
      color: $black;
      text-transform: uppercase;
      line-height: 40px;
      outline: none;
      border: none;
      border-radius: 60px;
      background: linear-gradient(135deg, rgba(255, 204, 62, 1) 0%, rgba(247, 183, 20, 1) 100%);
      box-shadow: 0 3px 0 0 #d19e19;
      cursor: pointer;
      transition: $trans;
      text-align: center;
      text-decoration: none !important;

      &:hover {
        background: linear-gradient(135deg, rgba(247, 183, 20, 1) 0%, rgba(255, 204, 62, 1) 100%);
      }
    }
  }
}

                                    //PRIVACY
.privacy_header {
  background-color: rgba(32,32,32,.8);
}
.privacy {
  padding-top: 200px;
  padding-bottom: 110px;

  h1 {
    margin-bottom: 30px;
    font-size: 46px;
    color: $black;

    b {
      color: $orange;
    }
  }

  h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 38px;
  }

  p {
    text-indent: 60px;
    font-size: 16px;
    text-align: justify;
  }

  ul {
    margin-bottom: 30px;
    padding-left: 60px;

    li {
      margin-bottom: 15px;
      font-size: 16px;
      color: $orange;
      text-align: justify;

      span {
        color: $black;
      }
    }
  }

  a {
    color: $orange;
    text-decoration: none;
    border-bottom: 1px solid $orange;
    transition: $trans;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}